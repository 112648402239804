import Layout from "components/layout";
import { useUI } from 'contexts/UI/UIManager'
import React, { useEffect, useMemo, useState } from 'react';
import Welcome from "components/onboarding/Welcome/Welcome";
import Dashboard from "./dashboard";
import { useRouter } from 'next/router'

const PENDING_STATES = [
  "Started",
  "Reviewed"
]

// TODO we need an endpoint that returns wether the user has completed the KYP or not,
// we shouldn't need to check this on the client.
const checkCompleteness = (kycData: object) => {
  if (!kycData) return // When loading kycData is null, ignore in the meantime.

  // Naively checks if legal terms were accepted
  //@ts-ignore
  return kycData.kyc_data.legal_terms_accepted
}

const Home = () => {

  const {
    kycData
  } = useUI();
  const router = useRouter()

  const [loading, setLoading] = useState(true)
  const isKYPComplete = useMemo(() => checkCompleteness(kycData), [kycData])

  useEffect(() => {
    if (kycData && kycData.kyc_data) {
      setLoading(false)
    }

    if (!isKYPComplete) router.push('/od')

  }, [kycData, loading])

  if (loading) {
    return (<div>Loading data</div>)
  }
  return (<>

    {

      kycData ? kycData.kyc_data && kycData.kyc_data.status && PENDING_STATES.includes(kycData.kyc_data.status) ?
        <Welcome />
        :
        <Dashboard />
        : ''
    }

  </>)

}

Home.Layout = Layout;
export default Home;