import Layout from "components/layout";
import { useUI } from 'contexts/UI/UIManager'
import React, { FormEvent, useEffect, useRef, useState } from 'react';

import ClientHeaderData from "forms/commons/client-data/client-header-data";
import Card from "components/Cards/Card";
import Link from "next/link";
import { useAuth0 } from "@auth0/auth0-react";

const Dashboard = () => {

    const {
        user,
        setModalVariant,
        setModalContent,
        closeModal,
        openModal,
        startLoading,
        closeLoading
    } = useUI();

    const [showPpm, setShowPPM] = useState(false)
    const {
        getAccessTokenSilently
    } = useAuth0();

    useEffect(() => {
        if (user && user.auth0_data) {

            /// user metadata can be null
            if (user.auth0_data.user_metadata) {

                /// user metadata might not contain ppm_opened key
                if (!user.auth0_data.user_metadata.ppm_opened) {
                    setShowPPM(true)
                }
            } else {
                setShowPPM(true)
            }

            if (showPpm) {
                let token = getAccessTokenSilently();

                ///update user metadata, so we'll be able to know that they already saw it
                fetch(`/api/user/update-user-metadata?user_id=${user.full_account.auth0_id}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/JSON'
                    },
                    body: JSON.stringify({ user_metadata: { ppm_opened: true } })
                }).then((res) => {
                    if (res.ok) {
                        console.log(res)
                    }
                })
            }
        }
    }, [])

    useEffect(() => {
        if (showPpm) {
            promptConfirm()
        }
    }, [showPpm])

    const promptConfirm = (): Promise<boolean> => {
        return new Promise((res, rej) => {
            setModalVariant("default")
            setModalContent(
                <div className="flex m-auto pt-2 bg-[#191720] items-center rounded-md h-80 lg:w-2/4">
                    <div className="p-4">
                        <div className='flex justify-center items-center'>
                            <span className='text-[#00D7A3] text-xl font-semibold'>AVISO:</span>
                        </div>
                        <div className='flex justify-center items-center pt-4'>
                            <span className='text-white text-center'>
                                Al continuar en PBG aceptas los terminos del presente&nbsp;
                                <button className='text-[#00D7A3] underline'
                                    onClick={async () => {

                                        startLoading()
                                        let token = getAccessTokenSilently();

                                        /// get file signed url
                                        let response = await fetch(`/api/gcp/get-ppm?id=${user.id}`, {
                                            headers: {
                                                'Authorization': `Bearer ${token}`
                                            }
                                        })

                                        if (response.ok) {
                                            const file = await response.json()
                                            /// open file signed url in another page
                                            window.open(file.file_url, '_blank');
                                        }

                                        closeLoading()
                                    }}
                                >
                                    Private Placement Memorandum
                                </button>
                                &nbsp;junto con todas sus cláusulas.
                            </span>
                        </div>
                        <div className='flex justify-center items-center pt-4'>
                            <button className="px-10 py-2 text-[#322E3F] bg-[#00D7A3] rounded"
                                onClick={async () => {
                                    closeModal()
                                }}
                            >
                                Entiendo y acepto
                            </button>
                        </div>
                    </div>
                </div>
            )
            openModal();
        })
    }

    return (
        <>
            <ClientHeaderData title={"¡Hola, buenas tardes!"} />
            <section className="space-y-5 lg:grid lg:grid-cols-2 gap-2 justify-center text-center items-center">

                <Card className="bg-[#4c1bd4]/30">
                    <span className="text-[#00D7A3]">Grafica de inversion</span>

                </Card>

                <Card className="bg-[#4c1bd4]/30 justify-center space-y-10">
                    <div className="text-[#00D7A3] font-lg font-bold">
                        ¡Invierte en acciones!
                    </div>
                    <Link href="/deposit" passHref>
                        <button className="bg-[#00D7A3] py-2 px-4 rounded">
                            <span className="text-[#322E3F]">¡Quiero invertir!</span>
                        </button>
                    </Link>
                </Card>
            </section>

            {user && user.kyc_full_data && user.kyc_full_data.kyc_data && user.kyc_full_data.kyc_data.status !== 'Approved' ?
                <div className="text-center space-y-5">
                    <span className="text-[#00D7A3] text-3xl font-bold">COMPLETA TU PERFIL</span>
                    <p>En la sección de Documentación podrás completar tu perfil en el orden que quieras o bien seguir en esta sección: </p>
                </div> : ''}

        </>
    )
}

Dashboard.Layout = Layout;
export default Dashboard;