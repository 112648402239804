import { FC } from "react"
import cn from 'classnames';
import s from './Main.module.css'
import c from 'components/layout/content/Content.module.css'

const Main: FC<{ secondary?: boolean, onClick?: (any: any) => any }> = ({ children, secondary, onClick }) => {
    return (<button
        className={cn(s.root, { [s.secondary]: secondary })}
        onClick={onClick}
    >
        {children}
    </button>)
}

export default Main