import s from './Welcome.module.css'
import c from 'components/layout/content/Content.module.css'

import cn from 'classnames'
import { CapitalLogo } from 'components/utils'

import Main from "components/ui/buttons/main/Main";
import Link from 'next/link'

const Welcome = () => {
    return <section className={cn(s.root)}>
        <CapitalLogo />
        <h1>
            Te damos la Bienvenida
        </h1>
        <h2 className={cn(c.secondary)}>
            Ahora que se creó tu cuenta, puedes continuar con el proceso.
        </h2>
        <p className='font-light'>
            Recuerda que con tu cuenta puedes acceder a la plataforma para continuar con el proceso de documentación cuando quieras.
        </p>
        <p className='font-light'>
            Ahora, continuemos con los detalles de tu perfil.
        </p>
        <Link href="/od" passHref>
            <a>
                <Main>
                    Iniciar
                </Main>
            </a>
        </Link>
        <footer className='font-light'>
            Al continuar estas aceptando los&nbsp;
            <a href='https://clientes.pbg.capital/documents/terminos_condiciones.pdf' target="_blank" rel="noopener noreferrer" className='text-[#00D7A3] underline'>TERMINOS y CONDICIONES</a>
            &nbsp;y&nbsp;
            <a href='https://clientes.pbg.capital/documents/aviso_privacidad.pdf' target="_blank" rel="noopener noreferrer" className='text-[#00D7A3] underline'>AVISO DE PRIVACIDAD</a>
        </footer>
    </section>
}

export default Welcome