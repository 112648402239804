import { useUI } from 'contexts/UI/UIManager';
import moment from "moment"

const ClientHeaderData = ({ title }: any) => {

    const {
        user
    } = useUI();

    return <section className='flex justify-between w-full pb-2'>
        <h1>
            {title}
        </h1>
        <span className='text-right'>
            <h4 className='font-bold'>
                {user && user.fiscal_type && user.fiscal_type == "PERSONA_FISICA" ? user.full_account?.personal_data?.first_name : user?.kyc_full_data?.moral_data_kyc?.name}
            </h4>
            <p>Miembro desde: {user && user.since ? moment(user.since).format("DD/MM/yyyy") : ''}</p>
        </span>
    </section>
}
export default ClientHeaderData;